<template>
  <div>
    <nav-bar />
    <div class="dialogWrapper">
      <div class="dialogWindow">
        <v-card-title class="tabWrapper">
          <div class="activeTab"><p>Odabir kategorije</p></div>
          <div :class="currentTab >= 2 ? 'activeTab' : 'tab'">
            <p>Unos podataka</p>
          </div>
          <div :class="currentTab >= 3 ? 'activeTab' : 'tab'">
            <p>Unos slika</p>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="currentTab === 1">
          <ChooseCategory v-model="currentTab" @goToNext="goToProductInfo" />
        </v-card-text>
        <v-card-text v-if="currentTab === 2">
          <ProductInfo v-model="currentTab" @goToPrevious="goToChooseCategory" @goToNext="goToProductImages" />
        </v-card-text>
        <v-card-text v-if="currentTab === 3">
          <ProductImages v-model="currentTab" @goToPrevious="goToProductInfo" @goToNext="goToProductAdded" />
        </v-card-text>
        <v-card-text v-if="currentTab === 4">
          <ProductAdded v-model="currentTab" />
        </v-card-text>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseCategory from "./ChooseCategory.vue";
import ProductInfo from "./ProductInfo.vue";
import ProductImages from "./ProductImages.vue";
import ProductAdded from "./ProductAdded.vue";
import NavBar from "../../Components/NavBar.vue";
// import Footer from "../Footer.vue";

export default {
  name: "AddProduct",
  data() {
    return {
      currentTab: 1,
    };
  },
  components: {
    ChooseCategory,
    ProductInfo,
    ProductImages,
    NavBar,
    ProductAdded,
    // Footer,
  },
  methods: {
    goToChooseCategory() {
      this.currentTab = 1;
    },
    goToProductInfo() {
      this.currentTab = 2;
    },
    goToProductImages() {
      this.currentTab = 3;
    },
    goToProductAdded() {
      this.currentTab = 4;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}

.dialogWindow {
  border-radius: 4px;
  margin: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 100%;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
}

.tab {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.activeTab {
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #ffff;
  background-color: #137547;
}

.activeTab:first-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #137547;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.activeTab:last-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #137547;
}

.activeTab p,
.tab p {
  margin: 0;
  font-size: 1rem;
  word-break: keep-all;
}

.v-card__subtitle,
.v-card__title {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .dialogWindow {
    width: 800px;
  }

  .dialogWindow {
    margin: 0 auto;
  }

  .tabWrapper {
    flex-direction: row;
  }

  .tab {
    width: 33%;
  }
  .activeTab {
    width: 33%;
  }
  .activeTab:first-child {
    border-top-right-radius: 0px;
  }
  .activeTab:last-child {
    border-top-right-radius: 4px;
  }
}
</style>
