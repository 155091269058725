<template>
  <div>
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" lazy-validation>
      <v-autocomplete
        v-model="choosenCategory"
        label="Kategorija"
        item-text="name"
        item-value="id"
        :items="allCategories"
        filled
        color="#137547"
        background-color="#ececef"
        :rules="categoryRules"
        @change="choosenCategoryChanged"
      ></v-autocomplete>
      <v-autocomplete
        v-model="choosenSubcategory"
        :label="allSubcategories.length == 0 ? 'Izaberite kategoriju prvo' : 'Podkategorija'"
        item-text="name"
        item-value="id"
        :items="allSubcategories"
        filled
        color="#137547"
        background-color="#ececef"
        :rules="subcategoryRules"
        :disabled="allSubcategories.length == 0"
      ></v-autocomplete>
    </v-form>
    <v-card-actions class="nextStepButton">
      <v-btn color="#137547" @click="submitForm">
        <p style="margin: auto; color: #ffff">SLEDEĆI KORAK</p>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ProductInfo",
  data() {
    return {
      loading: false,
      allCategories: [],
      choosenCategory: 0,
      allSubcategories: [],
      choosenSubcategory: 0,
      categoryRules: [(v) => !!v || "Kategorija je obavezna."],
      subcategoryRules: [(v) => !!v || "Podategorija je obavezna."],
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    ...mapActions(["AddProductCategory"]),
    async submitForm() {
      if (this.$refs.form.validate()) {
        let tempCat = this.allCategories.find((x) => x.id === this.choosenCategory);
        let catName = tempCat.name;
        let tempSubcat = this.allSubcategories.find((x) => x.id === this.choosenSubcategory);
        let subcatName = tempSubcat.name;
        let tempObject = {
          category: this.choosenCategory,
          categoryName: catName,
          subcategory: this.choosenSubcategory,
          subcategoryName: subcatName,
        };
        await this.AddProductCategory(tempObject).then(() => {
          this.$emit("goToNext");
        });
      }
    },
    getCategories() {
      axios.get("Category").then((response) => {
        let tempCat = response.data.map((item) => {
          return {
            ...item,
          };
        });
        this.allCategories = tempCat;
        if (this.currentProduct.category > 0) {
          let tempSubCat = tempCat.find((x) => x.id === this.currentProduct.category);
          this.allSubcategories = tempSubCat.subCategories;
        }
      });
    },
    getSubcategories(categoryId) {
      let tempObject = this.allCategories.find((x) => x.id === categoryId);
      this.allSubcategories = tempObject.subCategories;
    },
    choosenCategoryChanged() {
      this.choosenSubcategory = 0;
      this.getSubcategories(this.choosenCategory);
    },
  },
  computed: {
    currentProduct() {
      return this.$store.getters.StateNewProduct;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.choosenCategory = this.currentProduct.category;
      this.choosenSubcategory = this.currentProduct.subcategory;
    });
  },
};
</script>

<style lang="scss" scoped>
.nextStepButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  color: "#137547";
}

@media screen and (min-width: 768px) {
}
</style>
