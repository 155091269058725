<template>
  <div class="dialogWrapper">
    <v-icon color="#137547" x-large>mdi-check-bold</v-icon>
    <p class="titleText">Uspešno ste kreirali oglas.</p>
    <button class="redirectButton" @click.prevent="redirectToMyProfile">Idi na moje oglase</button>
  </div>
</template>

<script>
export default {
  name: "ProductAdded",
  methods: {
    redirectToMyProfile() {
      this.$router.push("/moj-nalog");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleText {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.redirectButton {
  padding: 0.8rem;
  color: #fff;
  background-color: #137547;
  border-radius: 4px;
}
</style>
